@tailwind base;
@tailwind components;
@tailwind utilities;

.border-gradient {
    border-image: linear-gradient(to right, #77a7df, #39d4b3) 1;
}

.red {
    background-color: red;
    color: white;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}